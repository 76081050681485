import React from 'react'
import Sidebar from '../../../../components/admin components/Sidebar'
import Adm_service_request from './Adm_service_request'

export default function Adm_service_request_wrapper() {
    return (

        <Sidebar type="Service & Return">
            <Adm_service_request />

        </Sidebar>

    )
}
