import React from "react";
import Sidebar from "../../../components/admin components/Sidebar";
import Create from "./Create";

export const CreateWrapper = () => {
  return (
    <Sidebar type="updateaccounts">
      <Create />
    </Sidebar>
  );
};
