import React from "react";
import Sidebar from "../../../components/admin components/Sidebar";
import { Settle } from "./Settle";

export const SettleWrapper = () => {
  return (
    <Sidebar type="accountdetails">
      <Settle />
    </Sidebar>
  );
};
