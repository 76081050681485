import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { Container, Row } from "react-bootstrap";

import { MyContext } from "../Contexts/Contexts";
import {
  CheckCircle,
  CropSquareOutlined,
  HighlightOff,
  SquareRounded,
} from "@mui/icons-material";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function InventoryAg() {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isHidden } = useContext(MyContext);
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const cellStyle = {
    fontSize: "13px",
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      flex: 2,
      field: "product_master.product_name",
      headerName: "Product Name",
      headerClass: "center-align-header",
      cellStyle: (params) => {
        return {
          textAlign: "left",
          fontSize: "13px",
          color: params.data.status === "outofstock" ? "red" : "inherit",
        };
      },
      // cellStyle: { textAlign: "left", fontSize: "13px" },
    },
    {
      flex: 1,
      field: "product_master.color",
      headerName: "Color",
      headerClass: "center-align-header",
      cellStyle,
      cellRendererFramework: (params) => {
        const { color, color_family } = params?.data?.product_master || {};

        if (color && color_family) {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                marginTop: "2px",
              }}
            >
              <SquareRounded
                style={{ color: color_family }}
                titleAccess={color}
              />
            </div>
          );
        } else {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                marginTop: "2px",
              }}
            >
              <CropSquareOutlined />
            </div>
          );
        }
      },
    },
    {
      flex: 1,
      field: "product_master.brand.brand_name",
      headerName: "Brand",
      headerClass: "center-align-header",
      cellStyle: (params) => {
        return {
          textAlign: "left",
          fontSize: "13px",
          color: params.data.status === "outofstock" ? "red" : "inherit",
        };
      },
    },
    {
      flex: 1,
      field: "product_master.product_type",
      headerName: "Type",
      headerClass: "center-align-header",
      cellStyle,
      cellStyle: (params) => {
        return {
          textAlign: "left",
          fontSize: "13px",
          color: params.data.status === "outofstock" ? "red" : "inherit",
        };
      },
    },
    {
      flex: 1,
      field: "batch_id",
      headerName: "Batch",
      cellStyle: (params) => {
        return {
          textAlign: "left",
          fontSize: "13px",
          color: params.data.status === "outofstock" ? "red" : "inherit",
        };
      },
      headerClass: "center-align-header",
    },

    {
      flex: 1,
      field: "total_quantity",
      headerName: "Total Qty",
      headerClass: "center-align-header",
      cellStyle: (params) => {
        return {
          textAlign: "left",
          fontSize: "13px",
          color: params.data.status === "outofstock" ? "red" : "inherit",
        };
      },
    },
    {
      flex: 1,
      field: "blocked_quantity",
      headerName: "Blocked Qty",
      headerClass: "center-align-header",
      cellStyle: (params) => {
        return {
          textAlign: "left",
          fontSize: "13px",
          color: params.data.status === "outofstock" ? "red" : "inherit",
        };
      },
    },
    {
      flex: 1,
      field: "mrp",
      headerName: "MRP",
      headerClass: "center-align-header",
      cellStyle: (params) => {
        return {
          textAlign: "left",
          fontSize: "13px",
          color: params.data.status === "outofstock" ? "red" : "inherit",
        };
      },
    },
    {
      flex: 1,
      field: "status",
      headerName: "Stock status",
      headerClass: "center-align-header",
      cellStyle,
      width: 150,
      cellRendererFramework: (params) => {
        const { status } = params.data;
        if (status === "instock") {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                marginTop: "2px",
              }}
            >
              <CheckCircle style={{ color: "#22c55e" }} titleAccess="instock" />
            </div>
          );
        } else if (status === "outofstock") {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                marginTop: "2px",
              }}
            >
              <HighlightOff
                style={{ color: "#ef4444" }}
                titleAccess="out of stock"
              />
            </div>
          );
        }
      },
    },
  ]);
  const axiosPrivate = useAxiosPrivate();

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
  }));

  //consuming
  const cellClickedListener = useCallback((event) => {
    // console.log(event);
  }, []);

  // Example load data from server
  useEffect(() => {
    const fetchApi = async () => {
      try {
        setLoading(true);
        const body = {
          isHidden: isHidden ? true : false,
        };
        const response = await axiosPrivate.post(`/inventory`, body);

        console.log("resss", response);

        setRowData(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchApi();
  }, []);

  return (
    <Container fluid>
      <Row>
        {/* Example using Grid's API */}
        {/* <button onClick={buttonListener}>Push Me</button> */}

        {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
        <div
          className="ag-theme-alpine"
          style={{
            width: "100%",
            height: 500,
            border: "1px solid #A6C991",
            fontSize: "13px",
          }}
        >
          <AgGridReact
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection="multiple" // Options - allows click selection of rows
            onCellClicked={cellClickedListener} // Optional - registering for Grid Event
            columnHoverHighlight={true}
            // suppressRowHoverHighlight= {false}
          />
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </Row>
    </Container>
  );
}
