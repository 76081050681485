import React from "react";
import { Button, IconButton, styled } from "@mui/material";

export const ButtonComp = ({ onClick, text, type }) => {
  const CancelButton = styled(Button)(({ theme }) => ({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    height: 40,
    width: "7rem",
    padding: "6px 12px",
    lineHeight: 1.5,
    color: theme.palette.getContrastText("#78716c"),
    backgroundColor: "#78716c",
    "&:hover": {
      backgroundColor: "#484644",
    },
  }));
  const SaveButton = styled(Button)(({ theme }) => ({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    height: 40,
    width: "7rem",
    padding: "6px 12px",
    lineHeight: 1.5,
    color: theme.palette.getContrastText("#78716c"),
    backgroundColor: "#4CAF50",
    "&:hover": {
      backgroundColor: "#2c822e",
    },
  }));

  const AddNewButton = styled(Button)(({ theme }) => ({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    height: 35,
    width: "7rem",
    padding: "6px 12px",
    lineHeight: 1.5,
    color: theme.palette.getContrastText("#78716c"),
    backgroundColor: "#3b82f6",
    "&:hover": {
      backgroundColor: "#2563eb",
    },
  }));
  return (
    <>
      {type === "green" && <SaveButton onClick={onClick}>{text}</SaveButton>}
      {type === "red" && <CancelButton onClick={onClick}>{text}</CancelButton>}
      {type === "blue" && <AddNewButton onClick={onClick}>{text}</AddNewButton>}
    </>
  );
};
