import { AttachFile } from "@mui/icons-material";
import React from "react";

export default function FileInput({ text,index, onChange }) {
  const handleFileChanges = (event,i) => {
    
    if (onChange) {
      console.log(event.target.files)
      console.log(index,i)

      onChange(event,i);
    }
  };
  return (
    <>
      <label
        title="Attach file"
        htmlFor={`file-input-${index}`} 
        className="file-input-label  "
      >
        <AttachFile /> {text}
        <input
          type="file"
          id={`file-input-${index}`}
          onChange={(event)=>{handleFileChanges(event,index)}}
          multiple
          style={{ display: "none" }}
        />
      </label>
    </>
  );
}
